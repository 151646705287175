<template>
  <div class="examination-body">
    <div class="examination-body-left" ref="examinationBodyLeft">
      <div
        class="examination-body-item"
        v-for="(item, index) in testInfo.test_titleList"
        :key="index"
        :ref="`stem${index}`"
      >
        <div class="examination-body-title">
          {{ index + 1 + "、" + item.question }}
        </div>
        <div class="examination-check-body">
          <div
            class="examination-check-item"
            :class="{
              active: items.active,
              'check-true':
                (items.isTrue == 2 && type == 1) || (items.isTrue && type == 2),
              'check-false': items.isTrue == 1 && type == 1,
            }"
            v-for="(items, inx) in item.jsonOptions"
            :key="inx"
            @click="selectClick(items, item.jsonOptions, item.questionType)"
          >
            {{ items.options + ". " + items.answer }}
          </div>
        </div>
        <div class="examination-details" v-if="type == 1">
          <div class="examination-title">
            <span class="details-state" :class="{ danger: !item.ifRight }">{{
              item.ifRight ? "回答正确" : "回答错误"
            }}</span>
            <span
              >您的答案:
              <em style="margin-left: 5px">{{ getAnswer(item) }}</em></span
            >
          </div>
          <!--          <div class="details-body">-->
          <!--            <div class="details-analysis">解析:</div>-->
          <!--            <div class="analysis-body">-->
          <!--              壬戌之秋，七月既望，苏子与客泛舟游于赤壁之下。清风徐来，水波不兴。举酒属客，诵明月之诗，歌窈窕之章。少焉，月出于东山之上，徘徊于斗牛之间。白露横江，水光接天。纵一苇之所如，凌万顷之茫然。浩浩乎如冯虚御风，而不知其所止；飘飘乎如遗世独立，羽化而登仙。-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div class="examination-body-right" :class="{ isFixed }" ref="isFixed">
      <div class="examination-right-title">
        <div class="title-name">{{ testInfo.courseName }}</div>
        <template v-if="type === 0">
          <el-progress :percentage="progress || 0" status="exception" />
          <div class="examination-progress">
            {{ complete }}/{{ testInfo.test_titleList.length }}
            <span>当前进度</span>
          </div>
        </template>
      </div>
      <div class="examination-item-body">
        <div
          class="question-number"
          :class="isActive(item)"
          v-for="(item, index) in testInfo.test_titleList"
          :key="index"
          @click="questionClick(item, index)"
        >
          {{ index + 1 }}
        </div>
      </div>
      <div class="question-legend" v-if="type == 1">
        <div class="legend-item">
          <div class="legend-item-box"></div>
          <span>正确</span>
        </div>
        <div class="legend-item danger">
          <div class="legend-item-box"></div>
          <span>错误</span>
        </div>
      </div>
      <div class="question-bottom">
        <template v-if="type == 0">
          <el-button
            type="danger"
            class="cancel-btn"
            plain
            size="mini"
            @click="handlerBack"
          >
            取消考试
          </el-button>
          <el-button
            type="danger"
            class="submit-btn"
            size="mini"
            @click="submitClick"
          >
            提交试卷
          </el-button>
        </template>
        <template v-else>
          <el-button
            type="danger"
            style="width: 100%"
            class="cancel-btn"
            size="mini"
            @click="handlerBack"
          >
            返回
          </el-button>
        </template>
      </div>
    </div>
    <div class="result-tip" v-if="resultShow">
      <div class="result-tip-content">
        <div class="tip-title">
          {{ IsChengGong === 0 ? "考试未通过" : "考试通过" }}
        </div>
        <div class="tip-img">
          <img
            v-if="IsChengGong === 0"
            src="@/assets/images/error-img.png"
            alt=""
          />
          <img v-else src="@/assets/images/success-img.png" alt="" />
        </div>
        <div class="tip-text">
          您的分数为
          <span
            :style="`color: ${
              IsChengGong == 0 ? '#b50413' : '#85ce61'
            };margin-left: 5px;`"
            >{{ fenShu }}</span
          >
        </div>
        <div
          class="tip-text"
          style="margin-top: 0"
          v-if="IsChengGong === 0 && IsChongXue === 1"
        >
          您的3次答题机会已用完，请重新学习
        </div>
        <div class="tip-btn">
          <div class="tip-item">
            <el-button
              style="width: 100%"
              size="mini"
              type="danger"
              @click="handlerBack"
            >
              确 定
            </el-button>
          </div>
          <div class="btn-flex">
            <div class="tip-item" v-if="IsChengGong === 0 && IsChongXue === 0">
              <el-button
                style="width: 100%"
                size="mini"
                type="danger"
                plain
                @click="handelError"
              >
                纠错查看
              </el-button>
            </div>
            <div class="tip-item" v-if="IsChengGong === 0 && IsChongXue === 0">
              <el-button
                style="width: 100%"
                size="mini"
                type="danger"
                plain
                @click="refreshPage"
              >
                重新考试
              </el-button>
            </div>
          </div>
          <div
            class="tip-item"
            v-if="
              (IsChengGong === 1 && IsChongXue === 0) ||
              (IsChengGong === 0 && IsChongXue === 1)
            "
          >
            <el-button
              style="width: 100%"
              size="mini"
              type="danger"
              plain
              @click="handelAnswer"
            >
              查看答案
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getErrorStudyTestTitleList,
  getStudyTestTitleList,
  getTrueStudyTestTitleList,
  postStudyTestTitleSend,
} from "@/api/specializedController";
import * as storage from "@/utils/storage";

export default {
  data() {
    return {
      isFixed: false,
      resultShow: false,
      testInfo: {
        courseName: "",
        test_titleList: [],
      },
      courseId: 0,
      fenShu: 0,
      IsChengGong: 0,
      IsChongXue: 0,
      type: 0, // 0 考试、 1 纠错、 2 查看答案
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
  computed: {
    progress() {
      return (this.complete / this.testInfo.test_titleList.length) * 100;
    },
    complete() {
      const dataList = JSON.parse(JSON.stringify(this.testInfo.test_titleList));
      dataList.map((item) => {
        const answer = item.jsonOptions.filter(
          (items) => items.active === true
        );
        item.answer = answer
          .map((items) => {
            return items.options;
          })
          .join(",");
        delete item.questionType;
        delete item.question;
        delete item.jsonOptions;
        return item;
      });
      return dataList.filter((item) => item.answer).length;
    },
    yearId() {
      return storage.getStore("yearId");
    },
  },
  created() {
    window.addEventListener("scroll", this.onScroll, false);
    this.courseId = this.$route.query.courseId;
    this.getTestTitleInfo();
    // this.handelError();
    // this.handelAnswer();
  },
  methods: {
    // 获取试卷
    getTestTitleInfo() {
      getStudyTestTitleList({
        courseId: this.courseId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        res.data.test_titleList.map((item) => {
          item.jsonOptions = JSON.parse(item.jsonOptions).map((items) => {
            items.active = false;
            return items;
          });
          return item;
        });
        this.testInfo = res.data;
      });
    },
    // 选择答案
    selectClick(item, option = [], type = 0) {
      if (this.type != 0) {
        return false;
      }
      if (type === 0 && item.active === false) {
        option.map((items) => {
          items.active = false;
          return items;
        });
      }
      item.active = !item.active;
    },
    questionClick(data, index) {
      document.documentElement.scrollTop =
        this.$refs[`stem${index}`][0].offsetTop;
    },
    // 提交试卷
    submitClick() {
      const dataList = JSON.parse(JSON.stringify(this.testInfo.test_titleList));
      dataList.map((item) => {
        const answer = item.jsonOptions.filter(
          (items) => items.active === true
        );
        item.answer = answer
          .map((items) => {
            return items.options;
          })
          .join(",");
        delete item.questionType;
        delete item.question;
        delete item.jsonOptions;
        return item;
      });
      console.log(dataList);
      postStudyTestTitleSend({
        c3R1ZHlDb3Vyc2VJZA: this.courseId,
        YW5zd2Vy: JSON.stringify(dataList),
        trainingPlanId: this.yearId,
      }).then((res) => {
        this.fenShu = res.data.fenShu;
        this.IsChengGong = res.data.isChengGong;
        this.IsChongXue = res.data.isChongXue;
        this.resultShow = true;
      });
    },
    // 重新考试
    refreshPage() {
      this.testInfo = {
        courseName: "",
        test_titleList: [],
      };
      this.resultShow = false;
      this.type = 0;
      this.IsChengGong = 0;
      this.IsChongXue = 0;
      this.getTestTitleInfo();
    },
    // 题目纠错
    handelError() {
      this.testInfo = {
        courseName: "",
        test_titleList: [],
      };
      this.type = 1;
      this.resultShow = false;
      getErrorStudyTestTitleList({
        courseId: this.courseId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        res.data.testTitleList.map((item) => {
          item.jsonOptions = JSON.parse(item.jsonOptions);
          return item;
        });
        this.testInfo = {
          courseName: res.data.courseName,
          test_titleList: res.data.testTitleList,
        };
      });
    },
    // 获取选择的答案
    getAnswer(data) {
      const item = JSON.parse(JSON.stringify(data));
      const answer = item.jsonOptions.filter((items) => items.isTrue != 0);
      return answer
        .map((item) => {
          return item.options;
        })
        .join(",");
    },
    // 查看答案
    handelAnswer() {
      this.type = 2;
      this.resultShow = false;
      this.testInfo = {
        courseName: "",
        test_titleList: [],
      };
      getTrueStudyTestTitleList({
        courseId: this.courseId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        res.data.testTitleList.map((item) => {
          item.jsonOptions = JSON.parse(item.jsonOptions);
        });
        this.testInfo = {
          courseName: res.data.courseName,
          test_titleList: res.data.testTitleList,
        };
      });
    },
    isActive(item) {
      if (this.type === 0) {
        const isTrue =
          item.jsonOptions.filter((item) => item.active).length > 0;
        if (isTrue) {
          return "question-danger";
        }
      } else if (this.type === 1) {
        return item.ifRight ? "question-success" : "question-danger";
      } else {
        return "question-success";
      }
    },
    onScroll() {
      if (document.documentElement.scrollTop >= 92) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.examination-body {
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  .examination-body-left {
    width: calc(100% - 385px);
    margin-right: 16px;
    background-color: #ffffff;
    padding: 28px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    .examination-body-item {
      padding-bottom: 24px;
      padding-top: 24px;
      border-bottom: 1px solid #ebebeb;
      .examination-body-title {
        font-size: 16px;
        color: #21252e;
        font-weight: normal;
        line-height: normal;
      }
      .examination-check-item {
        margin-top: 16px;
        padding: 8px;
        box-sizing: border-box;
        font-size: 14px;
        color: #505363;
        background: #f5f5f5;
        border-radius: 4px;
        border: 1px solid #f5f5f5;
        cursor: pointer;
        position: relative;
        &:hover {
          color: #1677fe;
          background: rgba(22, 119, 254, 0.06);
          box-sizing: border-box;
          border: 1px solid rgba(22, 119, 254, 0.5);
        }
        &.active {
          color: #1677fe;
          background: rgba(22, 119, 254, 0.06);
          box-sizing: border-box;
          border: 1px solid rgba(22, 119, 254, 0.5);
        }
        &.check-true {
          color: #61b261;
          background: rgba(97, 178, 97, 0.06);
          box-sizing: border-box;
          border: 1px solid rgba(97, 178, 97, 0.5);
        }
        &.check-false {
          color: #ba2926 !important;
          background: rgba(186, 41, 38, 0.06) !important;
          box-sizing: border-box;
          border: 1px solid rgba(186, 41, 38, 0.5) !important;
        }
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
    .examination-details {
      margin-top: 16px;
      .examination-title {
        margin-bottom: 8px;
        span {
          margin-right: 24px;
          color: #21252e;
          em {
            font-style: normal;
            color: #1677fe;
          }
        }
        .details-state {
          border-radius: 3px;
          padding: 6px 10px;
          background: #61b261;
          font-size: 12px;
          color: #ffffff;
          margin-right: 24px;
          &.danger {
            background-color: #ba2926;
          }
        }
      }
      .details-body {
        padding: 12px;
        background-color: #f5f5f5;
        border-radius: 4px;
        font-size: 14px;
        .details-analysis {
          color: #818496;
        }
        .analysis-body {
          color: #21252e;
        }
      }
    }
  }
  .examination-body-right {
    width: 320px;
    box-sizing: border-box;
    padding: 16px;
    background: #ffffff;
    &.isFixed {
      position: sticky;
      top: 0;
    }
    .examination-right-title {
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 12px;
    }
    .examination-progress {
      color: #818496;
      font-size: 12px;
      font-weight: 400;
      span {
        margin-left: 8px;
      }
    }
    .examination-item-body {
      padding: 16px 0;
      display: flex;
      flex-direction: row;
      gap: 12px 14px;
      flex-wrap: wrap;
      align-content: flex-start;
      align-self: stretch;
      .question-number {
        display: inline-block;
        background: #f5f5f5;
        box-sizing: border-box;
        border: 1px solid #e5e5e5;
        padding: 8px 0px;
        width: 36px;
        text-align: center;
        height: 36px;
        cursor: pointer;
        border-radius: 4px;
        &.question-success {
          background: #61b261;
          border: 1px solid #61b261;
          color: #ffffff;
        }
        &.question-warning {
          background: #e57d2b;
          color: #ffffff;
          border: 1px solid #e57d2b;
        }
        &.question-danger {
          background: #ba2926;
          color: #ffffff;
          border: 1px solid #ba2926;
        }
      }
    }
    .question-bottom {
      padding-top: 16px;
      border-top: 1px solid #ebebeb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      .cancel-btn {
        width: 80px;
      }
      .submit-btn {
        width: calc(100% - 90px);
      }
    }
    .question-legend {
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .legend-item {
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .legend-item-box {
          width: 24px;
          height: 16px;
          border-radius: 4px;
          background: #61b261;
          margin-right: 5px;
        }
        &.danger .legend-item-box {
          background-color: #ba2926;
        }
      }
    }
  }
  .result-tip {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .result-tip-content {
      position: relative;
      z-index: 100;
      border-radius: 8px;
      width: 180px;
      text-align: center;
      padding: 32px 32px 24px 32px;
      margin-bottom: 80px;
      background-color: #ffffff;
      .tip-title {
        color: #3d3d3d;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
      }
      .tip-img {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tip-text {
        margin-top: 16px;
        font-size: 14px;
        color: #505363;
      }
      .tip-btn {
        margin-top: 28px;
        .tip-item {
          margin-top: 12px;
        }
        .btn-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          .tip-item {
            flex: 1;
          }
        }
      }
    }
  }
}
::v-deep .el-progress-bar {
  padding-right: 0;
}
::v-deep .el-icon-circle-close {
  display: none;
}
</style>
